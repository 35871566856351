<template>
    <lassoLayout class="page-login">
        <div class="page_form_layout full_page">
            <v-container>
                <div class="wrap_form_layout">
                    <h2 class="login">Log in!</h2>
                    <!--            <a href="#facebook" class="facebook_connect" target="_blank" @click="showComingSoonDialog = true">-->
                    <!--                <span class="facebook_icon"></span>-->
                    <!--                Connect with facebook-->
                    <!--            </a>-->
                    <div class="facebook_connect" v-ripple @click="showComingSoonDialog = true">
                        <span class="facebook_icon"></span>
                        Connect with facebook
                    </div>
                    <div class="or">
                        <span>Or</span>
                    </div>
                    <form action="#" class="form">
                        <div class="email">
                            <label>Email</label>
                            <input autofocus type="email" v-model="email" tabindex="1" v-on:keyup.enter="logIn" @blur="$v.email.$touch()">
	                        <div class="error-msg" v-if="!$v.email.$error && !$v.email.maxLength">Email must be 128 characters or less</div>
	                        <div class="error-msg" v-if="$v.email.$error">Please enter a valid email address</div>
                        </div>
                        <div class="password">
                            <div>
                                <label>Password</label>
                                <button type="button" class="show_password" @click="switchVisibility('password')"
                                        v-html="passwordType === 'password'? 'Show' : 'Hide'"></button>
                            </div>
                            <input :type="passwordType" v-model="password" tabindex="2" v-on:keyup.enter="logIn">
	                        <div class="error-msg" v-if="!$v.password.$error && !$v.password.maxLength">Password must be 128 characters or less</div>
                        </div>
                    </form>
                    <div class="message_error">
                        <template v-if="LOGIN_STATUS.success === false">
                            {{LOGIN_STATUS.message}}
                        </template>
                    </div>

                    <button tabindex="3" v-if="email && password && !$v.email.$invalid && !$v.password.$invalid" type="button" class="log_in c2a_btn" v-ripple @click="logIn" :class="{'loading': loader}">
                        <v-progress-circular :width="2" indeterminate></v-progress-circular>
                        Log in
                        <span class="arrow">
                            <span class="line_svg"></span>
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            </svg>
                        </span>
                    </button>
                    <button v-else type="button" class="log_in c2a_btn" v-ripple disabled>Log in</button>
                    <div class="recovery_password">
                        <router-link :to="{name: 'forgot-password'}" tabindex="4">Recover password</router-link>
                    </div>
                    <router-link :to="{name: 'registration'}" class="new_account" tabindex="5">New Account</router-link>
                </div>
                <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import lassoLayout from "@/layouts/lassoLayout.vue";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

const ComingSoonDialog = () => import('@/components/dialogs/comingSoonDialog');

export default {
    name: 'login',
    components: {
        lassoLayout,
        ComingSoonDialog
    },
    data() {
        return {
            loader: false,
            showComingSoonDialog: false,
            email: '',
            password: '',
            passwordType: 'password',
        }
    },
    validations: {
        email: {
			email,
            required,
	        maxLength: maxLength(128)
        },
        password: {
            required,
	        maxLength: maxLength(128)
        },
    },
	watch: {
		email() {
			this.$v.$reset();
			this.message = '';
		},
		password() {
			this.message = '';
		}
	},
    computed: {
        ...mapGetters(['AUTH_DATA', 'LOGIN_STATUS', 'NEED_LOGIN_REDIRECT_PATH']),
    },
	mounted() {
		if (this.AUTH_DATA.accessToken) {
			this.$router.push({name: 'explore'});
		}
	},

	methods: {
        ...mapActions(['LOGIN', 'CLEAN_EXPLORE_GRID_ALBUMS', 'GET_BASKET']),
        switchVisibility() {
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
        },
        logIn() {
	        // this.$v.email.$touch();
	        // if (this.$v.email.$error === false) {
			// 	alert('Good');
	        // }
			// return;
            if (this.email && this.password) {
                this.loader = true;

                const userData = {
                    email: this.email,
                    password: this.password
                };
                this.LOGIN(userData)
                    .then(() => {
                        if (this.LOGIN_STATUS.success === true) {
                                this.CLEAN_EXPLORE_GRID_ALBUMS();
                                if (this.AUTH_DATA.verify === true && this.AUTH_DATA.firstLogin === false) {
									this.GET_BASKET();

	                                let addAlbumToFavorites = localStorage.getItem('add_album_to_favorites');
	                                if (addAlbumToFavorites) {
		                                this.$store.dispatch('ADD_ALBUM_TO_FAVORITES', {'music_release_id': addAlbumToFavorites})
			                                .then(() => {
				                                localStorage.removeItem('add_album_to_favorites');
			                                });
	                                }
									let addTrackToFavorites = JSON.parse(localStorage.getItem('add_track_to_favorites'));
									if (addTrackToFavorites) {
										let params = {
											'song_id': addTrackToFavorites.song.id,
											'album_id': addTrackToFavorites.album.id
										}
										this.$store.dispatch('ADD_TRACK_TO_FAVORITES', params)
											.then(() => {
												localStorage.removeItem('add_track_to_favorites');
											});
									}

	                                if (this.NEED_LOGIN_REDIRECT_PATH) {
		                                this.$router.push(this.NEED_LOGIN_REDIRECT_PATH.substring(1));
										return;
	                                }

                                    this.$router.push(this.$route.query.redirect || '/');
                                }
                                if (this.AUTH_DATA.verify === false) {
                                    this.$router.push({name: 'email-verification'});
                                }
                                if (this.AUTH_DATA.firstLogin === true && this.AUTH_DATA.verify === true) {
                                    this.$router.push({name: 'profile-basics'});
                                }
                        }

                        if (this.LOGIN_STATUS.message === 'not_verified_email') {
                          this.$router.push({name: 'email-verification'});
                        }
                    })
                    .finally(() => this.loader = false);
            }
        }
    }
}
</script>

<style lang="scss">
.page-login {
    padding-top: 20px;
}
</style>
